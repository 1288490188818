<router-outlet></router-outlet>
<div class="container my-5 text-muted text-center">
  Shanti Vault<br />
  {{ "versionNumber" | i18n: version }}
  <br /><br />
  <div class="small">
    A modified version of the Vaultwarden Web Vault for Shanti Hospice.<br>
    <a href="https://oss.shantihospice.com/Web%20Vault/" target="_blank">Source code</a>

  </div>
</div>
